<script>
  import GanttHeader from "./GanttHeader.svelte";
  import GanttRow from "./GanttRow.svelte";
// import { primaryScale } from "../stores.js";
  // import { primaryScaleStart, primaryScaleEnd } from "../stores.js";
  import { primaryColumns, vocabMap, zoomLevel } from "../stores.js";
  // import { secondaryColumns } from "../stores.js";
  // import GanttHeaderPrimaryScale from './GanttHeaderPrimaryScale.svelte';
  import {
      differenceInCalendarDays,
      format,
      getDayOfYear,
      parseISO,
  } from "date-fns";
  import deDeLocale from "date-fns/locale/de";
  import { getContext } from "svelte";
  import { get } from "svelte/store";
  import { apiGET, getVocabMap } from "../api";

  export let items = [];
  export let tableStartDate;
  export let tableEndDate;
  export let zoomLevels;
  export let ganttMode = "week";

  const rowlabeltype = getContext("rowlabeltype");
  const rooturl = getContext("rooturl");
  // const objecturl = getContext("objecturl");
  let colorMapping = getContext("colorMapping");
  let categoryField = getContext("categoryField");

  let query = {
    sort_on: 'getObjPositionInParent',
    sort_order: 'ascending',
  }

  query["criteria"] = [{
    i: 'portal_type',
    o: 'plone.app.querystring.operation.selection.any',
    v: rowlabeltype,
  }]

  const query_attributes = [
    "UID",
    "Title",
  ]

  // used to find row labels from CT's:
  let searchurl = new URL(rooturl);
  if(searchurl.pathname.endsWith("/view")) {
    searchurl.pathname = searchurl.pathname.replace("/view", "");
  }
  searchurl.pathname += "/@@getVocabulary"
  let searchParams = new URLSearchParams(searchurl.search);
  searchParams.set("name", "plone.app.vocabularies.Catalog")
  searchParams.set("query", JSON.stringify(query))
  searchParams.set("attributes", JSON.stringify(query_attributes))
  // searchParams.append("metadata_fields", "UID")
  searchurl.search = searchParams.toString();

  let rowHeight = 30;
  let rowCategories = new Map();
  let primaryHeaders;

  zoomLevels = zoomLevels || {
    0: {
      primaryScale: 7,
      primaryHeaders: ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"],
    },
  };
  primaryHeaders = zoomLevels[0].primaryHeaders;

  function calcStartSpanFromDates(start, end) {
    if(!start){
      console.log("no start date given, skip!")
      return
    }
    if(!end){
      console.log("no end date given, skip!")
      return
    }
    let itemStartDate = parseISO(start);
    let itemEndDate = parseISO(end);
    let itemStartInScale;
    let itemEndInScale;
    let itemStart;
    let itemSpan;
    let overflowLeft = false;
    let overflowRight = false;
    let itemDates;
    if (itemStartDate > tableEndDate || itemEndDate < tableStartDate) return;
    if (itemStartDate < tableStartDate) {
      itemStartInScale = tableStartDate;
      overflowLeft = true;
    } else {
      itemStartInScale = itemStartDate;
    }
    if (itemEndDate > tableEndDate) {
      itemEndInScale = tableEndDate;
      overflowRight = true;
    } else {
      itemEndInScale = itemEndDate;
    }
    if (ganttMode === "monthly") {
      itemStart = getDayOfYear(itemStartInScale);
    } else {
      itemStart = itemStartInScale.getDay();
    }
    itemSpan = differenceInCalendarDays(itemEndInScale, itemStartInScale) + 1;
    itemDates = {
      startDate: format(itemStartDate, "P", { locale: deDeLocale }),
      endDate: format(itemEndDate, "P", { locale: deDeLocale }),
      start: itemStart,
      span: itemSpan,
      overflowLeft: overflowLeft,
      overflowRight: overflowRight,
    };
    return itemDates;
  }

  class CatRow {
    constructor(
      rowColorBg,
      rowColorFont,
      rowItemColorBg,
      rowItemColorFont,
      items
    ) {
      this.rowColorBg = rowColorBg;
      this.rowColorFont = rowColorFont;
      this.rowItemColorBg = rowItemColorBg;
      this.rowItemColorFont = rowItemColorFont;
      this.items = items;
    }
  }

  function configureCatRow(catRow, categoryToken){
    // move this data to probs of the view, to avoid the call
    if (!colorMapping) {
      colorMapping = [
        {
          row_label: "",
          row_color_fg: "transparent",
          row_color_font: "black",
          row_item_color_fg: "lightblue",
          row_item_color_font: "black",
        },
      ];
    }
    for (const colorMap of colorMapping) {
      if (colorMap.row_label === categoryToken) {
        catRow.rowColorBg = colorMap.row_color_bg;
        catRow.rowColorFont = colorMap.row_color_font;
        catRow.rowItemColorBg = colorMap.row_item_color_bg;
        catRow.rowItemColorFont = colorMap.row_item_color_font;
      }
    }
    return catRow;
  }

  async function buildRows(items) {
    let rowCategories = new Map();
    // const vocab = await getVocabMap(searchurl.href);
    for (const item of items) {
      console.log("buildRows item:")
      console.dir(item)
      let rowItem;
      let category = "other";
      let catRow;
      const categoryTokenField = item[`${categoryField}`];
      let categoryToken = undefined;
      if (categoryTokenField){
        categoryToken = categoryTokenField.UID;
      }
      if (categoryTokenField) {
        category = categoryTokenField.title;
      }

      if (rowCategories.has(category)) {
        catRow = rowCategories.get(category);
      } else {
        catRow = new CatRow("transparent", "black", "lightblue", "black", []);
        catRow = configureCatRow(catRow, categoryToken)
        rowCategories.set(category, catRow);
      }

      let itemDates = calcStartSpanFromDates(item.start, item.end);
      if (!itemDates) {
        console.log("no itemDates, skip!")
        continue;
      }
      rowItem = {
        name: item.title,
        description: item.description,
        startDate: itemDates.startDate,
        endDate: itemDates.endDate,
        start: itemDates.start,
        span: itemDates.span,
        overflowLeft: itemDates.overflowLeft,
        overflowRight: itemDates.overflowRight,
        url: item["@id"],
        uid: item.UID,
      };
      catRow.items = [...catRow.items, rowItem];
      rowCategories.set(category, catRow);
    }
    console.log(rowCategories)
    return rowCategories;
  }

  $: (async () => (rowCategories = await buildRows(items)))();

  $: primaryScale = zoomLevels[$zoomLevel].primaryScale;
  $: primaryHeaders = zoomLevels[$zoomLevel].primaryHeaders;

  $: {
    $primaryColumns = Array.from(Array(primaryScale), (e, i) => {
      return { id: i + 1, name: primaryHeaders[i] };
    });
  }
</script>

<div class="GanttTable">
  <div id="popover-content" />
  <GanttHeader {primaryHeaders} />
  {#each [...rowCategories] as [catLabel, catRow]}
    <GanttRow
      rowId={catLabel}
      {rowHeight}
      {primaryScale}
      --row-color-font={catRow.rowColorFont}
      --row-color-bg={catRow.rowColorBg}
      --row-item-color-font={catRow.rowItemColorFont}
      --row-item-color-bg={catRow.rowItemColorBg}
      items={catRow.items}
    />
  {/each}
</div>

<style>
  .GanttTable {
    max-width: 100%;
  }
</style>
