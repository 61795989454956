<!--<svelte:options tag="gantt-chart" /> -->
<svelte:options accessors={true} />

<script>
  import { addWeeks, endOfWeek, getWeek, startOfWeek } from "date-fns";
  import deDeLocale from "date-fns/locale/de";
  import { getContext, setContext } from 'svelte';
  import { apiStoreGET } from "./api";
  import GanttNavi from "./components/GanttNavi.svelte";
  import GanttTable from "./components/GanttTable.svelte";
  import { vocabMap } from "./stores";

  export let centerDate = new Date(Date.now());
  const objecturl = getContext("objecturl");
  const defaultquery = getContext("defaultquery");
  const rowlabeltype = getContext("rowlabeltype");

  const colorMappingMap = new Map([
    ["Facility", "facility"],
    ["Location", "location"],
    ["Placeholder Plan Art", "plan_art"],
    ["Placeholder Plan Status", "plan_status"],
    ["Requirement Type", "requirement_type"],
    ["Track", "track"],
    ["Facility Category", "facility_category"],
    ["IBN Stage", "ibn_stage"],
    ["Eba Area", "eba_area"],
    ["Document Type", "document_type"],
    ["Applicable Tsi", "applicable_tsi"],
    ["Eiu", "eiu"],
    ["Eigv Classification", "eigv_classification"],
    ["Type Of Measure", "type_of_measure"],
    ["Protocol Type", "protocol_type"],
    ["Event Type", "dbec_event_type"],
    ["Procedure", "procedure"],
  ])

  const categoryField = colorMappingMap.get(rowlabeltype) || "track_view_row_label";
  setContext("categoryField", categoryField)

  let query = {
    sort_on: 'start',
    sort_order: 'ascending',
    criteria: [...defaultquery]
  }

  const query_attributes = [
    "UID",
    "Title",
  ]

  let searchurl = new URL(objecturl);
  if(searchurl.pathname.endsWith("/view")) {
    searchurl.pathname = searchurl.pathname.replace("/view", "");
  }
  searchurl.pathname = searchurl.pathname + "/";
  let searchParams = new URLSearchParams(searchurl.search);
  searchParams.set("query", JSON.stringify({"query": query["criteria"]}))
  searchurl.search = searchParams.toString();
  // searchurl.search = new URLSearchParams("sort_on=start&sort_order=ascending&metadata_fields=start&metadata_fields=end&metadata_fields=Subject&metadata_fields=UID&metadata_fields=termin_art").toString();
  // const searchurlString = searchurl.toString();
  let currentWeekStartDate;
  let currentWeekEndDate;
  let currentWeekNumber;
  let eventsResponse = apiStoreGET();

  // let primaryBackgroundColor = "#B0E6CE";
  // let primaryFontColor = "#27664A";
  // let secondaryBackgroundColor = "#FFA963";
  // let secondaryAlternativeBackgroundColor = "#FED8C3";
  // let secondaryFontColor = "#4B2609";

  $: {
    currentWeekStartDate = startOfWeek(centerDate, {
      locale: deDeLocale,
    });
    currentWeekEndDate = endOfWeek(centerDate, {
      locale: deDeLocale,
    });
    currentWeekNumber = getWeek(centerDate, { locale: deDeLocale });

    // we need to search only for the time range here, when DateRangeInRange is fixed:
    console.log(searchurl.href)
    eventsResponse = apiStoreGET(searchurl.href);
  }

  function previous(e) {
    centerDate = addWeeks(centerDate, -1);
  }

  function now(e) {
    centerDate = addWeeks(new Date(Date.now()), 0);
  }

  function next(e) {
    centerDate = addWeeks(centerDate, 1);
  }

  setContext("previous", previous)
  setContext("next", next)
  setContext("now", now)
</script>

  {#await $eventsResponse}
    loading ...
  {:then data}
    <div class="Gantt">
      <div class="Week">
        <GanttNavi gantttitle={currentWeekNumber} />
        <GanttTable
          items={data.items}
          tableStartDate={currentWeekStartDate}
          tableEndDate={currentWeekEndDate}
          categoryField="termin_art"
        />
      </div>
    </div>
  {/await}

<style>
</style>
