<script>
  import { addYears, endOfYear, getYear, startOfYear } from "date-fns";
  import deDeLocale from "date-fns/locale/de";
  import { getContext, setContext } from "svelte";
  import { apiStoreGET } from "./api";
  import GanttNavi from "./components/GanttNavi.svelte";
  import GanttTable from "./components/GanttTable.svelte";

  export let centerDate = new Date(Date.now());
  const objecturl = getContext("objecturl");

  let searchurl = new URL(objecturl);
  console.log(searchurl.pathname)
  if(searchurl.pathname.endsWith("/view")) {
    searchurl.pathname = searchurl.pathname.replace("/view", "");
  }
  let searchParams = new URLSearchParams(searchurl.search);
  searchParams.set("sort_on", "start")
  searchParams.set("sort_order", "ascending")
  searchParams.append("metadata_fields", "start_m")
  searchParams.append("metadata_fields", "end_m")
  searchParams.append("metadata_fields", "Subject")
  searchParams.append("metadata_fields", "UID")
  searchParams.append("metadata_fields", "facility")
  searchurl.search = searchParams.toString();
  let currentYearStartDate;
  let currentYearEndDate;
  let currentYearNumber;
  let eventsResponse = apiStoreGET(searchurl.href);

  let primaryBackgroundColor = "#B0E6CE";
  let primaryFontColor = "#27664A";
  let secondaryBackgroundColor = "#FFA963";
  let secondaryAlternativeBackgroundColor = "#FED8C3";
  let secondaryFontColor = "#4B2609";

  let zoomLevels = {
    0: {
      primaryScale: 365,
      primaryHeaders: [
        "Januar",
        "Februar",
        "März",
        "April",
        "Mai",
        "Juni",
        "Juli",
        "August",
        "September",
        "Oktober",
        "November",
        "Dezember",
      ],
    },
  };

  $: {
    currentYearNumber = getYear(centerDate, { locale: deDeLocale });
    currentYearStartDate = startOfYear(centerDate, {
      locale: deDeLocale,
    });
    currentYearEndDate = endOfYear(centerDate, { locale: deDeLocale });

    eventsResponse = apiStoreGET(searchurl.href);
  }

  function previous(e) {
    centerDate = addYears(centerDate, -1);
  }

  function now(e) {
    centerDate = addYears(new Date(Date.now()), 0);
  }

  function next(e) {
    centerDate = addYears(centerDate, 1);
  }

  setContext("previous", previous);
  setContext("next", next);
  setContext("now", now);
</script>

<!--<svelte:options tag="gantt-chart" /> -->

<div class="svelte-app-wrapper">
  {#await $eventsResponse}
    loading ...
  {:then data}
    <div class="Gantt">
      <GanttNavi gantttitle={currentYearNumber} />
      <GanttTable
        items={data.items}
        tableStartDate={currentYearStartDate}
        tableEndDate={currentYearEndDate}
        {zoomLevels}
        ganttMode="monthly"
        categoryField="termin_art"
      />
    </div>
  {/await}
</div>

<style>
</style>
