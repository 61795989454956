<script>
  import { setContext } from "svelte";
  import GanttMonthly from "./GanttMonthly.svelte";
  import GanttMultiWeek from "./GanttMultiWeek.svelte";
  import GanttWeek from "./GanttWeek.svelte";
  // import { getVocabMap } from "./api";
  // import { vocabMap } from "./stores";

  export let currentobjecturl = "";
  export let objecturl = "";
  export let navigationrooturl = "";
  export let defaultquery;
  export let rowlabeltype = "";
  export let colormapping = [];
  export let trackstartkm = 0;
  export let trackendkm = 5;

  if(defaultquery){
    defaultquery = JSON.parse(defaultquery)
  }

  setContext("baseurl", currentobjecturl);
  setContext("objecturl", objecturl);
  setContext("defaultquery", defaultquery);
  setContext("rowlabeltype", rowlabeltype);
  setContext("rooturl", navigationrooturl);
  setContext("colorMapping", colormapping);

  // let categoryvocaburl =
  //   navigationrooturl + "/@vocabularies/collective.taxonomy.termin_art";
  let ganttMode = "week"; // week, multiWeek, monthly

  // async function extractVocab(categoryVocabInfo) {
  //   let vocabTitleByToken = new Map();
  //   let vocab = categoryVocabInfo;
  //   let items = vocab.items;
  //   for (const item of items) {
  //     vocabTitleByToken.set(item.token, item.title);
  //   }
  //   return vocabTitleByToken;
  // }

</script>

<div class="btn-group gantt-mode" role="group" aria-label="Gantt-Modus">
  <button
    on:click={() => {
      ganttMode = "week";
    }}
    class="btn"
    class:active={ganttMode === "week"}>Woche</button
  >
  <button
    on:click={() => {
      ganttMode = "multiWeek";
    }}
    class="btn"
    class:active={ganttMode === "multiMeek"}>mehre Wochen</button
  >
  <button
    on:click={() => {
      ganttMode = "monthly";
    }}
    class="btn"
    class:active={ganttMode === "monthly"}>Jahr</button
  >
</div>

{#if ganttMode === "week"}
  <GanttWeek
  />
{/if}
{#if ganttMode === "multiWeek"}
  <GanttMultiWeek
  />
{/if}
{#if ganttMode === "monthly"}
  <GanttMonthly
  />
{/if}

<style>
  :global(:root) {
    --popover-background-color: darkcyan;
    --popover-border-color: darkcyan;
    --popover-text-color: white;
    --popover-link-color: lightskyblue;
  }
  :global(.Gantt){
    margin-bottom: 4em;
  }

  .gantt-mode {
    display: flex;
    justify-content: flex-end;
  }
</style>
